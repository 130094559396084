import * as React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';
import commonMessageValues from '../templates/formatjs-message-values';

const PublicServices = (): JSX.Element => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.public_services}`,
    message: (
      <>
        <div className={(intl.locale === "en" ? "text-ltr" : "text-rtl")}>
          <FormattedMessage id='message_public_services_p1' values={commonMessageValues}/>
          <div className="ui ordered list">
            <div className="item"><FormattedMessage id='message_public_services_item1' values={commonMessageValues}/></div>
            <div className="item"><FormattedMessage id='message_public_services_item2' values={commonMessageValues}/></div>
            <br/>
          </div>
        </div>
        <FormattedMessage id='message_public_services_p2' values={commonMessageValues}/>
      </>
    )
  }, {
    header: `${messages.services_of_organization_with_ERP}`,
    message: (
      <>
        <div className="ui bulleted list">
          <div className="item">{messages.message_public_services_item3}</div>
          <div className="item">{messages.message_public_services_item4}</div>
          <div className="item">{messages.message_public_services_item5}</div>
          <div className="item">{messages.message_public_services_item6}</div>
          <div className="item">{messages.message_public_services_item7}</div>
        </div>
      </>
    )
  }, {
    header: `${messages.services_of_organization_without_ERP}`,
    message: (<p>{messages.message_public_services_item8}</p>)
  }];

  return (<TextPage locale={intl.locale} title={`${messages.public_services}`} textBlocks={textBlocks}/>);
};

export default PublicServices;